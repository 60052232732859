<template>
  <div>
    <v-fade-transition mode="out-in">
      <router-view class="white lighten-1" >
      </router-view>
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  name: 'PageFamily',
};
</script>

<style></style>
